import React, { useState, useEffect, useContext } from "react"
import Login from "./login"
import { Link } from "gatsby"

import {
  AccountStatus,
  registerUser,
  hasBorrowerAccess,
  getAccountForEmail,
  getAccountStatus,
} from "../../services/axieTreeApi"
import AxiaCircleLogo from "../logos/axiaCircleLogo"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"
import { navigate } from "gatsby"

const alphaTitle = "Hurray!"
const alphaSubtitle1 = "Thank you for registering!"
const alphaSubtitle2 =
  "You are in our queue for account creation. Please wait for us to email you your new borrower account and password. This could take some time."
const alphaSubtitle3 = "In the meantime:"

const nonAlphaTitle = "Coming soon!"
const nonAlphaSubtitle1 = "Thank you for your interest!"
const nonAlphaSubtitle2 =
  "We did not find an Alpha account associated with your email but added you to our waitlist. You’ll be among the first to know when we are publicly available."
const nonAlphaSubtitle3 = "In the meantime:"

const Registration = props => {
  const context = useWeb3React()
  const { library, account } = context

  const [accountStatus, setAccountStatus] = useState()

  const [inputEmail, setInputEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [hasRegistered, setHasRegistered] = useState(false)

  const [registeredTitle, setRegisteredTitle] = useState()
  const [registeredSubtitle1, setRegisteredSubtitle1] = useState(
    "Thank you for your interest!"
  )
  const [registeredSubtitle2, setRegisteredSubtitle2] = useState(
    "Looks like you already registered. Please wait a little longer whie we prepare your account!"
  )
  const [registeredSubtitle3, setRegisteredSubtitle3] = useState(
    "In the meantime:"
  )

  useEffect(() => {
    if (account) {
      getAccountStatus(account).then(response => {
        setAccountStatus(response)
        if (response === AccountStatus.REGISTERED) {
          navigate("/app")
        }
      })
    }
  }, [account])

  const handleChange = event => {
    let tmpEmail = event.target.value
    setInputEmail(tmpEmail)

    setValidEmail(isEmailValid(tmpEmail))
  }

  const register = event => {
    // event.preventDefault()

    ;(async () => {
      let response = await hasBorrowerAccess(inputEmail)
      let accountMade = await getAccountForEmail(inputEmail)

      if (accountMade === null) {
        setHasRegistered(true)
        if (response === true) {
          registerUser(context.account, inputEmail)
          setRegisteredTitle(alphaTitle)
          setRegisteredSubtitle1(alphaSubtitle1)
          setRegisteredSubtitle2(alphaSubtitle2)
          setRegisteredSubtitle3(alphaSubtitle3)
        } else {
          setRegisteredTitle(nonAlphaTitle)
          setRegisteredSubtitle1(nonAlphaSubtitle1)
          setRegisteredSubtitle2(nonAlphaSubtitle2)
          setRegisteredSubtitle3(nonAlphaSubtitle3)
        }
      } else {
        alert(
          `This email is already associated ${accountMade}. Please try a connecting to a different address."`
        )
      }
    })()
  }

  const showAxies = () => {
    props.registrationCallback()
  }

  const isEmailValid = email => {
    return /\S+@\S+\.\S+/.test(email)
  }

  if (!account) {
    return <Login />
  }

  if (accountStatus === AccountStatus.PENDING || hasRegistered) {
    return (
      <div className="w-full h-full axietree-bg">
        <div className="registration">
          <div className="self-center">
            <AxiaCircleLogo />
          </div>
          <h1 className="text-axietree-accent" style={{ fontSize: "24px" }}>
            {registeredTitle}
          </h1>
          <h2 className="description text-white text-left">
            {registeredSubtitle1}
          </h2>
          <h2 className="description text-white text-left">
            {registeredSubtitle2}
          </h2>
          <h2 className="description text-white text-left">
            {registeredSubtitle3}
          </h2>
          <div className="description grid grid-cols-1 sm:grid-cols-2 gap-3 text-center">
            <Link
              to="/app"
              className="registration-item bg-axietree-accent text-background focus:outline-none active:bg-card-stroke"
            >
              Browse the marketplace
            </Link>
            <a
              target="_blank"
              href="https://discord.gg/hQWpGkUQp2"
              className="registration-item bg-axietree-accent text-background focus:outline-none active:bg-card-stroke"
            >
              Join Discord
            </a>
            <a
              target="_blank"
              href="https://axietree.medium.com/"
              className="registration-item bg-axietree-accent text-background focus:outline-none active:bg-card-stroke"
            >
              Follow On Medium
            </a>
            <a
              target="_blank"
              href="https://twitter.com/axie265"
              className="registration-item bg-axietree-accent text-background focus:outline-none active:bg-card-stroke"
            >
              Follow Axia on Twitter
            </a>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="w-full h-full axietree-bg">
        <div className="registration">
          <div className="self-center">
            <AxiaCircleLogo />
          </div>
          <h1 className="text-axietree-accent" style={{ fontSize: "24px" }}>
            Let’s get borrowing
          </h1>
          <div className="description">
            <h2 className="text-white text-left">
              AxieTree is currently in public beta. Please use at your own risk.
            </h2>
          </div>
          <div className="description">
            <h2 className="text-white text-left">
              Please register your email address to use AxieTree
            </h2>
          </div>
          <input
            className="registration-item focus:outline-none focus:ring-2 focus:ring-card-stroke focus:border-transparent border-axietree-accent bg-background text-axietree-accent placeholder-axietree-accent focus:placeholder-transparent"
            type="email"
            placeholder="Enter email address"
            onChange={handleChange}
            value={inputEmail}
          ></input>
          <button
            className={
              (validEmail ? "opacity-100" : "opacity-20") +
              " registration-item bg-axietree-accent text-background focus:outline-none active:bg-card-stroke"
            }
            onClick={register}
            disabled={!validEmail}
          >
            Register
          </button>
        </div>
      </div>
    )
  }
}

export default Registration
