import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import Menu from "../components/menu"

//Logo
import Logo from "./logos/logo"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"
import { injected, network } from "../app/connectors"
import Timer from "./timer"

const Header = ({ siteTitle }) => {
  const context = useWeb3React()
  const { connector, library, account, activate } = context

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  const connectWallet = () => {
    setActivatingConnector(injected)
    activate(injected)
  }

  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <>
      <header className="header">
        {showMenu && <Menu toggleMenu={toggleMenu} />}
        <button className="menu-btn" onClick={toggleMenu}>
          <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
          </svg>
        </button>
        <div className="logo">
          <Link to="/app">
            <Logo width="75" height="48" />
          </Link>
        </div>
        <div className="metamask-btn">
          {account ? (
            <div className="">
              Wallet {account.substring(0, 6)}...
              {account.slice(-4)}
            </div>
          ) : (
            !!library === false && (
              <button className="" onClick={() => connectWallet()}>
                Connect Wallet
              </button>
            )
          )}
        </div>
      </header>
      <div className="earnings mb-4">
        {/* TODO: add timer here */}
        <div>
          <Timer />
        </div>
        <h2 className="font-normal">
          This project is in alpha. Use at your own risk.
        </h2>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
