import React from "react"
import ReactDOM from "react-dom"
import Countdown from "react-countdown"

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <h2 className="font-bold">
      <a
        href="https://axietree.medium.com/announcing-axietrees-alpha-program-7724890978b9"
        target="_blank"
      >
        Epoch: {days}d {hours}h {minutes}m {seconds}s • SLP requirement: 1000
      </a>
    </h2>
  )
}

const times = [
  1610240400000,
  1611450000000,
  1612659600000,
  1613869200000,
  1615078800000,
] //jan 9 2020 5PM PST - Mar 7 2020 5PM PST, every 2 weeks

const Timer = () => {
  let now = Date.now()
  let nextEpoch
  for (var i = 0; i < times.length; i++) {
    if (now < times[i]) {
      console.log(`now ${now} next ${times[i]}`)
      nextEpoch = new Date(times[i])
      console.log(nextEpoch)
      break
    }
  }

  return (
    <div>
      <Countdown date={nextEpoch} renderer={renderer} />
    </div>
  )
}

export default Timer
