import React, { useEffect, useState } from "react"

import MetamaskLogo from "../logos/metamaskLogo"

//Web3React Provider
import { useWeb3React } from "@web3-react/core"
import {
  injected,
  // network
} from "../../app/connectors"

const Login = () => {
  console.log("Hello from NFTREE!")

  const context = useWeb3React()
  const { connector, account, activate } = context

  useEffect(() => {
    console.log(account)
  }, [account])

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  const connectWallet = () => {
    setActivatingConnector(injected)
    activate(injected)
  }

  return (
    <div className="axietree-bg sm:border-t sm:border-card-stroke w-full h-full">
      <div className="registration flow flow-col">
        <div className="metamask-icon rounded-ful my-2">
          <div className="mx-auto">
            <MetamaskLogo width="80" height="80" />
          </div>
        </div>
        <h1 className="text-axietree-accent my-2">Welcome Player!</h1>
        <p className="text-white text-center my-2">
          Please connect Metamask to get started
        </p>
        <button
          className="registration-item bg-axietree-accent text-background focus:outline-none active:bg-card-stroke my-2"
          onClick={connectWallet}
        >
          Connect Metamask
        </button>
      </div>
    </div>
  )
}

export default Login
