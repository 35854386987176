import axios from "axios"
const API = `${process.env.API}`

export const AccountStatus = Object.freeze({
  REGISTERED: Symbol("Registered"),
  UNREGISTERED: Symbol("No Account Available"),
  PENDING: Symbol("Pending"),
})

export const getUser = () => {
  //TODO: implement registration check
  return null
}

export const getAccountForEmail = async email => {
  return axios
    .post(`${API}/account_for_email`, {
      email: email,
    })
    .then(response => {
      return response.data.address
    })
}

export const getAccountStatus = async address => {
  return axios.get(`${API}/account-status/${address}`).then(response => {
    let status = response.data.status
    if (status === "No Account Available") {
      return AccountStatus.UNREGISTERED
    } else if (status === "Pending") {
      return AccountStatus.PENDING
    } else if (status === "Registered") {
      return AccountStatus.REGISTERED
    }
  })
}

export const getAxies = async axieIDs => {
  return axios.get(`${API}/axies?ids=[${axieIDs}]`).then(response => {
    return response.data.axies
  })
}

//Get's all axies, 10 at a time
export const getAllAxies = async axieIDs => {
  var start = 0
  var end = axieIDs.length > 10 ? 10 : axieIDs.length
  var data = []

  while (data.length < axieIDs.length) {
    var responseAxies = await getAxies(axieIDs.slice(start, end))

    data = [...data, ...responseAxies]

    start = end
    end = end + 10 > axieIDs.length ? axieIDs.length : end + 10
  }

  return data
}

export const registerUser = async (address, email) => {
  return axios.post(`${API}/registerUser`, {
    publicAddress: address,
    email: email,
  })
}

export const hasBorrowerAccess = async email => {
  return axios
    .post(`${API}/borrower_whitelist`, {
      email: email,
    })
    .then(response => {
      return response.data.hasAccess
    })
}

export const hasLenderAccess = async address => {
  return axios
    .post(`${API}/lender_whitelist`, {
      address: address,
    })
    .then(response => {
      return response.data.hasAccess
    })
}
